<!--
 * @Descripttion: 跟进记录
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-07 08:45:25
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-11 08:58:09
-->
<template>
    <div class="followRecords">
        <div class="screen-wrap">
            <commonSelect
                @changeDate="changeDate"
                @clickTagDate="clickTagDate"
            ></commonSelect>
            <div class="sw-item">
                <div class="search-wrap">
                    <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                    <el-button @click="clickSearch" type="primary">搜索</el-button>
                </div>
                <el-button @click="clickCreation" type="primary">创建记录</el-button>
            </div>
        </div>
        <el-table :data="tableData" border style="width: 100%" v-loading="loading">
            <el-table-column align="center" prop="id" label="ID" width="100"></el-table-column>
            <el-table-column align="center" prop="system_admin.nickname" label="客服负责人" width="100"></el-table-column>
            <el-table-column align="center" prop="title" label="标题" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column align="center" prop="contact_name" label="联系人" width="150"></el-table-column>
            <el-table-column align="center" prop="remarks" label="备注" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column align="center" prop="create_timex" label="填写时间" width="200"></el-table-column>
            <el-table-column align="center" prop="create_timex" label="操作" width="100">
                <template slot-scope="scope">
                    <div class="operation-wrap">
                        <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                            <i @click="clickEdit(scope.row)" class="iconfont el-icon-edit"></i>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除" placement="top">
                            <i @click="clickDel(scope.row)" class="iconfont el-icon-delete"></i>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination-wrap">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-size="10"
                layout="total, prev, pager, next"
                :total="total">
            </el-pagination>
        </div>
        <el-dialog title="创建跟进记录" 
            :visible.sync="isCreateRecord" 
            :before-close="handleClose"
            custom-class="dialog-wrap"
            :close-on-click-modal="false">
            <div class="content">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="标题" prop="title">
                        <el-input v-model="ruleForm.title"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人" prop="contact_name">
                        <el-input v-model="ruleForm.contact_name"></el-input>
                    </el-form-item>
                    <el-form-item label="备注" prop="remarks">
                        <el-input rows="6" type="textarea" v-model="ruleForm.remarks"></el-input>
                    </el-form-item>
                </el-form>
                <div class="btn-wrap">
                    <el-button v-if="editId<0" :loading="btnLoading" @click="submitForm" type="primary">创 建</el-button>
                    <el-button v-else :loading="btnLoading" @click="submitFormEdit" type="primary">编 辑</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import common from "@/utils/common.js"
import commonSelect from "@/components/commonSelect.vue"
export default {
    components: {
      commonSelect
    },
    data () {
        return {
            keyword: '',
            userId: '',
            epId: '',
            dateType: '',
            startDate: '',
            endDate: '',
            tableData: [],
            loading: false,
            currentPage: 1,
            total: 0,
            isCreateRecord: false,
            ruleForm: {
                title: '',
                remarks: '',
                contact_name: ''
            },
             rules: {
                remarks: [
                    { required: true, message: '请输入备注', trigger: 'blur' },
                ],
            },
            btnLoading: false,
            editId: -1,
            contactOptions: [{
                value: 1,
                label: '手机'
            },{
                value: 2,
                label: '微信'
            }]
        }
    },
    mounted () {
        let info = JSON.parse(localStorage.getItem('brand'));
        if(info.name){
            this.epId = info.id;
        }else{
            this.epId = info.ep_id;
        }
        this.getShopfollowList();
    },
    methods: {
        // 确定创建
        submitForm(){
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let params = {
                        ep_id: this.epId,
                        title: this.ruleForm.title,
                        remarks: this.ruleForm.remarks,
                        contact_name: this.ruleForm.contact_name,
                    }
                    this.btnLoading = true;
                    common.connect("/customerservicev1/enterprise_brand_follow/store",params,(res)=>{
                        if(res.isSuccess == 1){
                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            this.getShopfollowList();
                            this.$refs.ruleForm.resetFields();
                            this.btnLoading = false;
                            this.isCreateRecord = false;
                        }else{
                            this.btnLoading = false;
                        }
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 确定编辑
        submitFormEdit(){
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let params = {
                        id: this.editId,
                        ep_id: this.epId,
                        title: this.ruleForm.title,
                        remarks: this.ruleForm.remarks,
                        contact_name: this.ruleForm.contact_name,
                    }
                    this.btnLoading = true;
                    common.connect("/customerservicev1/enterprise_brand_follow/update",params,(res)=>{
                        if(res.isSuccess == 1){
                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            this.getShopfollowList();
                            this.btnLoading = false;
                            this.isCreateRecord = false;
                            this.editId = -1;
                            this.ruleForm.title = '';
                            this.ruleForm.content = '';
                            this.ruleForm.contact_name = '';
                        }else{
                            this.btnLoading = false;
                        }
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 编辑页
        clickEdit(row){
            this.editId = row.id;
            common.connect("/customerservicev1/enterprise_brand_follow/edit",{id: row.id},(res)=>{
                this.isCreateRecord = true;
                this.ruleForm.title = res.data.title;
                this.ruleForm.remarks = res.data.remarks;
                this.ruleForm.contact_name = res.data.contact_name;
            });
        },
        // 删除
        clickDel(row){
            this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect("/customerservicev1/enterprise_brand_follow/delete",{id: row.id},(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getShopfollowList();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 点击创建
        clickCreation(){
            this.isCreateRecord = true;
        },
        // 关闭弹窗
        handleClose(){
            this.isCreateRecord = false;
            this.editId = -1;
            this.$refs.ruleForm.resetFields();
        },
        // 获取列表数据
        getShopfollowList(){
            let params = {
                keyword: this.keyword,
                user_id: this.userId,
                ep_id: this.epId,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
                page: this.currentPage
            }
            this.loading = true;
            common.connect("/customerservicev1/enterprise_brand_follow/index",params,(res)=>{
                this.total = res.data.count;
                this.tableData = res.data.list;
                this.loading = false;
            });
        },
        // 搜索
        clickSearch(){
            this.getShopfollowList()
        },
        // 日期选择
        changeDate(startDate,endDate){
            this.currentPage = 1;
            this.startDate = startDate.slice(0,11)+' 00:00:01';
            this.endDate = endDate.slice(0,11)+' 23:59:59';
            this.getShopfollowList()
        },
        clickTagDate(item){
            this.dateType = item.label;
            this.currentPage = 1;
            if(item.label != 'date'){
                this.startDate = '';
                this.endDate = '';
                this.getShopfollowList();
            }
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getShopfollowList();
        }
    },
}
</script>

<style lang='scss'>
.followRecords{
    padding: 20px;
    .screen-wrap{
        .sw-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 0;
            .select-wrap{
                margin-right: 20px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .title{
                    margin-right: 10px;
                }
            }
            .search-wrap{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .el-input{
                    width: 200px;
                    margin-right: 10px;
                }
            }
        }
    }
    .operation-wrap{
        .iconfont{
            font-size: 16px;
            padding: 0 10px;
            cursor: pointer;
        }
    }
    .pagination-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 20px;
    }
    .dialog-wrap{
        width: 800px;
        .content{
            margin-left: 20px;
            padding: 30px 0;
            .el-input{
                width: 650px;
            }
            .el-textarea{
                width: 650px;
            }
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
}
</style>
