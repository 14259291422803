/*
 * @Descripttion: 柱状图
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-05 12:07:54
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-07-05 12:09:23
 */
import * as echarts from 'echarts'

function wordBarEcharts(el,data) {
    var option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
            type: 'shadow'
            }
        },
        grid: {
            left: '4%',
            right: '2%',
            bottom: '3%',
            containLabel: true
        },
        legend: {
            right: 'center',
        },
        xAxis: [
            {
            type: 'category',
            data: data.categories
            }
        ],
        yAxis: [
            {
            type: 'value',
            axisLine:{
                "show":false
            },
            }
        ],
        color: ['#5484ec','#6cbcc4','#5ca45c','#f4bc44','#ec742c'],
        series: [
            {
            name: '口碑邀约',
            type: 'bar',
            barGap: 0,
            label: {
                show: true,
                position: 'top'
            },
            emphasis: {
                focus: 'series'
            },
            data: data.series[0].data
            },
            {
            name: '口碑填写',
            type: 'bar',
            label: {
                show: true,
                position: 'top'
            },
            emphasis: {
                focus: 'series'
            },
            data: data.series[1].data
            },
            {
            name: '参与门店',
            type: 'bar',
            label: {
                show: true,
                position: 'top'
            },
            emphasis: {
                focus: 'series'
            },
            data: data.series[2].data
            },
        ]
    };
    let myChart = echarts.init(document.getElementById(el));
    myChart.setOption(option)
}
  
  
export {
    wordBarEcharts,
}
    