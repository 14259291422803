<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-12-19 09:25:46
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-03-20 16:27:04
-->
<template>
    <div class="brandColumn">
        <div class="title-wrap"></div>
        <div class="content-wrap">
            <div class="bc-left">
                <div class="check-all-wrap" v-loading="iloading">
                    <div class="tree-wrap">
                        <el-tree :data="data1" 
                            :props="defaultProps1" 
                            ref="tree"
                            accordion
                            node-key="id"
                            :show-checkbox="showCheckbox1"
                            :default-checked-keys="checkedKeys1"
                            >
                        </el-tree>
                    </div>
                </div>
            </div>
            <div class="bc-right">
                <div class="check-all-wrap" v-loading="iloading">
                    <div class="check-wrap">
                        <el-checkbox v-model="checked" @change="checkedAll"> 全选/反选</el-checkbox>
                    </div>
                    <div class="tree-wrap">
                        <el-tree :data="data" 
                            :props="defaultProps" 
                            ref="tree"
                            accordion
                            node-key="id"
                            show-checkbox
                            :default-checked-keys="checkedKeys"
                            @check="currentChange"
                            >
                        </el-tree>
                    </div>
                </div>
                <div class="dialog-footer">
                    <el-button :loading="loading" type="primary" @click="submitPower">确 定</el-button>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import common from "@/utils/common.js"
export default {
    data () {
        return {
            epId: '',
            data1: [],
            checkedKeys1: [],
            defaultProps1: {
                children: 'children',
                label: 'label'
            },
            showCheckbox1: false,
            
            checked: false, 
            iloading: false,
            data: [],
            checkedKeys: [],
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            shopIds: [],
            loading: false
        }
    },
    mounted () {
        let info = JSON.parse(localStorage.getItem('brand'));
        if(info.name){
            this.epId = info.id;
        }else{
            this.epId = info.ep_id;
        }
        this.getMenuTree();
        this.getHasMenuTree();
    },
    methods: {
        submitPower(){
            // if(this.shopIds.length==0){
            //     this.$message.warning('请先选择要分配的权限');
            //     return
            // }
            let params = {
                ep_id: this.epId,
                enterprise_menu_ids: JSON.stringify(this.shopIds)
            }
            this.loading = true
            common.connect("/customerservicev1/enterprise_brand/assignMenuTree",params,(res)=>{
                this.loading = false
                this.$message.success('分配权限成功！')
                this.getHasMenuTree();
            });
        },
        // 选中门店
        currentChange(nodeObj,selectedObj){
            let list = [] 
            let data = []
            selectedObj.checkedNodes.forEach(v=>{
                if(v.id){
                    list.push(v.id)
                }
            })
            data = selectedObj.halfCheckedKeys
            this.shopIds = list.concat(data);
            this.checked = false;
        },
        checkedAll(){
            if (this.checked) {
                //全选
                let data = []
                let list = []
                const keys = this.$refs.tree.getCheckedKeys() // 获取已勾选节点的key值
                console.log(keys);
                this.$nextTick(()=>{
                    this.$refs.tree.setCheckedNodes(this.data);
                })
                this.data.forEach(v1=>{
                    data.push(v1.id)
                    v1.children.forEach(v2=>{
                        list.push(v2.id)
                    })
                })
                this.shopIds = list.concat(data);
            }else{
                //取消选中
                this.$nextTick(()=>{
                    this.$refs.tree.setCheckedKeys([]);
                })
                this.shopIds = [];
            }
        },
        //获取树列表
        getMenuTree(){
            this.iloading = true;
            common.connect("/customerservicev1/enterprise_brand/menuTree",{ep_id: this.epId},(res)=>{
                let list = [];
                let idList = []
                res.data.forEach((v1,i)=>{
                    list.push(v1.id);
                    v1.children.forEach(v2=>{
                        idList.push(v2.id)
                    })
                })
                this.data = res.data;
                this.iloading = false;
            }); 
        },
        //获取树列表
        getHasMenuTree(){
            let params = {
                ep_id: this.epId
            }
            this.iloading = true;
            common.connect("/customerservicev1/enterprise_brand/hasMenuTree",params,(res)=>{
                let list = [];
                let idList = []
                res.data.forEach((v1,i)=>{
                    list.push(v1.id);
                    v1.children.forEach(v2=>{
                        idList.push(v2.id)
                    })
                })
                this.data1 = res.data;
                this.iloading = false;
            }); 
        },
    },
}
</script>

<style lang='scss'>
.brandColumn{
    background: #f3f3f3;
    .title-wrap{
        width: 100%;
        height: 15px;
        background: #fff;
    }
    .content-wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .bc-left{
            flex: 1;
            background: #fff;
            margin: 15px 20px 0px 0px;
            padding: 20px;
            border-radius: 10px;
            height: 660px;
        }
        .bc-right{
            flex: 1;
            background: #fff;
            margin: 15px 0px 0px 0px;
            padding: 20px;
            border-radius: 10px;
            height: 660px;
            position: relative;
            .dialog-footer{
                position: absolute;
                bottom: 30px;
                right: 30px;
            }
        }
    }
}
</style>
