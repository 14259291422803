<!--
 * @Descripttion: 基础信息
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-06-29 11:53:59
 * @LastEditors: dxl 1668645906@qq.com
 * @LastEditTime: 2023-08-24 15:05:47
-->
<template>
    <div class="basicInformations">
        <el-descriptions :column="1" size="medium" v-if="info.name" border v-loading="loading"
            labelClassName="labelClassName" contentClassName="contentClassName">
            <el-descriptions-item label="企业名称">{{info.name}}</el-descriptions-item>
            <el-descriptions-item label="品牌名称">{{info.enterprise.name}}</el-descriptions-item>
            <el-descriptions-item label="品牌logo">
                <div class="logo-wrap">
                    <img v-if="info.logo !='undefined'" @click="clickImage(info.logo)" :src="info.logo" class="pic" />
                </div>
            </el-descriptions-item>
            <el-descriptions-item label="品牌海报">
                <div class="logo-wrap">
                    <!-- <img v-if="info.poster_logo" @click="clickImage(image[0])" :src="image[0]" class="pic" /> -->
                    <!-- <div v-else>
                        <el-button @click="updataImage" plain type="primary">上传海报</el-button>
                    </div> -->
                    <div class="upload-wrap">
                        <aliyun-upload 
                            :limitSize="5" :limit="1" :isShopTip="false" v-model="image"
                            :btnDisplay="image.length<1" listType="picture-card" @uploaded="uploaded"
                            :isAccept="true" acceptType="png" :sizePxWh="true">
                        </aliyun-upload>
                        <div v-if="image.length>0" class="fileList-box">
                            <div @click="delImageList" class="delFile">x</div>
                            <el-image fit="contain" class="pic"
                                :preview-src-list="image" :src="image[0]"/>
                        </div>
                        <div>只能上传单图，图片格式png，正方形尺寸小于500 </div>
                    </div>
                </div>
            </el-descriptions-item>
            <el-descriptions-item label="客服电话">{{info.customer_service_mobile}}</el-descriptions-item>
            <el-descriptions-item v-if="info.system_admin != null" label="市场负责人">{{info.system_admin.nickname}}</el-descriptions-item>
            <el-descriptions-item v-if="info.is_cooperate == 1 && userInfo.kf_level !=2" label="品牌售价">{{info.unit_price/100}}</el-descriptions-item>
            <el-descriptions-item v-if="info.is_cooperate == 1" label="经销商单价">{{info.unit_price_dealer/100}}</el-descriptions-item>
            <el-descriptions-item label="分账功能">
                <el-tooltip placement="top" :content="info.is_open_billsplitting==1?'开启':'关闭'">
                    <el-switch v-model="info.is_open_billsplitting" 
                        active-color="#13ce66" 
                        inactive-color="#ff4949"
                        @change="changeBillsplitting(info.id)"
                        >
                    </el-switch>
                </el-tooltip>
            </el-descriptions-item>
            <el-descriptions-item v-if="info.is_cooperate == 1" label="服务单价">{{info.service_price/100}}</el-descriptions-item>
            <el-descriptions-item v-if="info.is_cooperate == 1" label="经销商划线价">{{info.unit_price_dealer_scribing/100}}</el-descriptions-item>
            <el-descriptions-item v-if="info.is_cooperate == 1" label="合约到期日期">{{info.contract_end_time}}</el-descriptions-item>
            <el-descriptions-item label="品牌荣誉">
                <div class="honor-wrap">
                    <div class="image" v-for="(item,index) in info.honor_imgs" :key="index">
                        <img @click="clickImage(item)" class="pic" :src="item" />
                    </div>
                </div>
            </el-descriptions-item>
            <el-descriptions-item label="品牌介绍">
                <div class="content" v-html="info.content"></div>
            </el-descriptions-item>
        </el-descriptions>
        <div v-if="isShowImages">
            <el-image-viewer 
            :on-close="closeViewer" 
            :url-list="imageList" />
        </div>
    </div>
</template>

<script>
import common from "@/utils/common.js"
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import AliyunUpload from "@/components/upload/AliyunUpload.vue";
export default {
    components: {
      ElImageViewer,
      AliyunUpload
    },
    data () {
        return {
            loading: false,
            epId: 0,
            info: {},
            userInfo: {},
            isShowImages: false,
            imageList: [],
            image: []
        }
    },
    mounted () {
        let info = JSON.parse(localStorage.getItem('brand'));
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        if(info.name){
            this.epId = info.id;
        }else{
            this.epId = info.ep_id;
        }
        this.getBaseShow();
    },
    methods: {
        uploaded(e){
            let params = {
                ep_id: this.epId,
                poster_logo: e
            }
            common.connect("/customerservicev1/enterprise_brand/posterLogo",params,(res)=>{
                this.getBaseShow();
            });
        },
        //删除logo图片
        delImageList(){
            this.image.splice(0, 1)
        },
        // 更新海报
        // updataImage(){
        //     let params = {
        //         ep_id: this.epId,
        //         poster_logo: this.poster_logo
        //     }
        //     common.connect("/customerservicev1/enterprise_brand/poster_logo",params,(res)=>{
        //         if(res.data.honor_imgs != ''){
        //             res.data.honor_imgs = JSON.parse(res.data.honor_imgs);
        //         }
        //         this.info = res.data
        //     });
        // },
        clickImage(item){
            this.isShowImages = true;
            this.imageList.push(item);
        },
        // 关闭查看器
        closeViewer() {
            this.isShowImages = false;
            this.imageList = [];
        },
        //开启/关闭分账功能
        changeBillsplitting(){
            let params = {
                ep_id: this.epId
            }
            this.loading = true;
            common.connect("/customerservicev1/enterprise_brand/setBillsplitting",params,(res)=>{
                this.loading = false;
            });
        },
        getBaseShow(){
            let params = {
                ep_id: this.epId
            }
            common.connect("/customerservicev1/enterprise_brand/baseShow",params,(res)=>{
                if(res.data.honor_imgs != ''){
                    res.data.honor_imgs = JSON.parse(res.data.honor_imgs);
                }
                this.info = res.data
                this.image[0] = res.data.poster_logo
            });
        }
    },
}
</script>

<style lang='scss'>
.basicInformations{
    padding: 20px;
    .labelClassName{
        width: 130px;
        font-size: 15px;
        color: #000;
        line-height: 40px;
    }
    .contentClassName{
        font-size: 15px;
        color: #666;
        margin-left: 20px;
        line-height: 40px;
        width: calc(100% - 170px);
    }
    .el-descriptions .is-bordered {
        table-layout: fixed;
    }
    .logo-wrap{
        width: 100%;
        .pic{
            width: 100px;
            cursor: pointer;
        }
    }
    .honor-wrap{
        overflow-x: auto;
        width: 100%;
        white-space: nowrap; // 不换行
        .image{
            display: inline-block;
            width: 180px;
            .pic{
                width: 100%;
                vertical-align: bottom;
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }
    .content{
        line-height: 34px;
        // margin-top: -15px;
    }
    .upload-wrap{
        padding-top: 2px;
        margin-bottom: -10px;
        .image-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            margin-top: 10px;
        }
        .fileList-box{
            width: 100px;
            height: auto;
            position: relative;
            margin-right: 10px;
            .delFile{
                position: absolute;
                top: 5px;
                left: 5px;
                cursor: pointer;
                width: 20px;
                height: 20px;
                border-radius: 100%;
                border: 1px #ddd solid;
                background: rgba(100, 100, 100, 0.5);
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 99;
            }
            .pic{
            width: 100%;
            }
        }
    }
}
</style>
