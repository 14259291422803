<!--
 * @Descripttion: 品牌详情
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-06-29 11:01:33
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-04-14 14:17:19
-->
<template>
    <div class="brandDataInfo" ref="brandDataInfo">
        <div class="title-wrap" v-if="info.name">
            <div class="tag"></div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ name: 'brandData' }">品牌列表</el-breadcrumb-item>
                <el-breadcrumb-item>品牌详情<span>({{info.name}})</span></el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="title-wrap" v-else>
            <div class="tag"></div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ name: 'brandData' }">品牌跟进记录</el-breadcrumb-item>
                <el-breadcrumb-item>品牌详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
       
        <div class="case-wrap tabs-wrap">
            <el-tabs v-model="activeName" type="card">
                <el-tab-pane label="门店数据" name="first">
                    <numberStores ref="numberStores"></numberStores>
                </el-tab-pane>
                <el-tab-pane label="基础信息" name="second">
                    <basicInformation ref="basicInformation"></basicInformation>
                </el-tab-pane>
                <el-tab-pane label="数据分析" name="thirdly">
                    <dataAnalysis ref="dataAnalysis"></dataAnalysis>
                </el-tab-pane>
                <el-tab-pane label="跟进记录" name="fourthly" v-if="userInfo.kf_level == 1 || userInfo.kf_level == 4">
                    <followRecords ref="followRecords"></followRecords>
                </el-tab-pane>
                <el-tab-pane label="品牌栏目" name="fifth" v-if="userInfo.kf_level == 1">
                    <brandColumn ref="brandColumn"></brandColumn>
                </el-tab-pane>
            </el-tabs>
        </div>
        <el-backtop target=".brandDataInfo" :right="20" :bottom="30"></el-backtop>
    </div>
</template>

<script>
import numberStores from './brandComponents/numberStores'
import basicInformation from './brandComponents/basicInformation'
import dataAnalysis from './brandComponents/dataAnalysis'
import followRecords from './brandComponents/followRecords'
import brandColumn from './brandComponents/brandColumn'
export default {
    components:{
        numberStores,
        basicInformation,
        dataAnalysis,
        followRecords,
        brandColumn
    },
    data () {
        return {
            activeName: 'first',
            userInfo: {},
            info: {},
            type: ''
        }
    },
    mounted () {
        this.type = this.$route.query.type
        if(this.type){
            this.activeName = this.type;
        }
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.info = JSON.parse(localStorage.getItem('brand'));
        let width =  this.$refs.brandDataInfo.offsetWidth - 400;
        this.$refs.dataAnalysis.setValue(width)
    },
    methods: {

    },
}
</script>

<style lang='scss'>
.brandDataInfo{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
            width: 4px;
            height: 15px;
            background: #409EFF;
            border-radius: 10px;
            margin-right: 10px;
        }
        .title{
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }
    .tabs-wrap{
        background: #fff;
        margin-top: 20px;
        border-radius: 6px;
        .el-tabs__header{
            padding-top: 20px;
            margin: 0 20px;
        }
    }
    .el-breadcrumb__item{
        .el-breadcrumb__inner{
            color: #000;
            font-weight: bold;
        }
        .is-link{
            color: #333;
            font-weight: 300;
        }
    }
}
</style>
